<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <span>入职日期：</span>

                        <a-range-picker
                            style="width: 240px;"
                            format="YYYY-MM-DD"
                            v-model="initialValue"
                            @change="onChange"
                        />
                    </a-col>
                    <a-col>
                        <a-input-search
                            v-model.trim="filterText"
                            placeholder="请输入搜索内容..."
                            style="width: 240px"
                            enter-button
                            @search="refresh"
                        />
                    </a-col>
                    <a-col>
                        <a-button type="primary" @click="Export()" v-if="isGranted('oa_newemp_excel')">
                            <a-icon type="download" />
                            <span>导出</span>
                        </a-button>
                    </a-col>
                </a-row>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <span>试用期结束日：</span>
                        <a-range-picker style="width: 240px;" format="YYYY-MM-DD" @change="RegularonChange" />
                    </a-col>
                    <a-col>
                        <a-tree-select
                            style="width: 200px;"
                            allowClear
                            multiple
                            @change="handleCompanyChange"
                            :placeholder="l('Company')"
                            tree-default-expand-all
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            v-model="comPanyId"
                        />
                    </a-col>
                    <a-col>
                        <a-tree-select
                            style="width: 200px;"
                            multiple
                            allowClear
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="DepTreeData"
                            :placeholder="l('Department')"
                            tree-default-expand-all
                            v-model="departmentId"
                            @change="refresh"
                        >
                        </a-tree-select>
                    </a-col>
                    <a-col>
                        <a-select
                            v-model="empType"
                            @change="refresh"
                            style="width: 140px;"
                            placeholder="员工类型"
                            allowClear
                        >
                            <a-select-option v-for="item in empTypeList" :key="item.itemDetailCode">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 32]">
                    <a-col>
                        <a-table
                            @change="sorterChange"
                            :columns="columns"
                            :pagination="false"
                            :scroll="{ y: scroll_y, x: 2200 }"
                            :rowKey="(data, index) => index"
                            :dataSource="tableData"
                        >
                        </a-table>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-pagination
                            class="pagination"
                            :total="totalItems"
                            v-model="pageNumber"
                            showSizeChanger
                            showQuickJumper
                            :showTotal="showTotalFun"
                            @change="onChangePage"
                            @showSizeChange="showSizeChange"
                        />
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import {
    CompanyServiceProxy,
    DepartmentServiceProxy,
    EnterpriseAttendCycleServiceProxy,
    OrganizationReportServiceProxy,
} from '../../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../../shared/utils';
import { abpService } from '../../../../shared/abp';
import moment from 'moment';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
    name: 'new-employee-report',
    mixins: [AppComponentBase],
    data() {
        return {
            zh_CN,
            //表格上方间隔
            defaultTop: 60,
            companyTreeList: [], //公司
            comPanyId: undefined, //公司id
            departmentId: undefined,
            //部门树
            DepTreeData: [],
            DepTree: [],
            empTypeList: [],
            empType: undefined,
            startTime: undefined, //开始时间
            endTime: undefined, //结束时间
            initialValue: undefined, //入职日期初始值
            regularonStartTime: undefined,
            regularonEndTime: undefined,
            filterText: undefined,
            // 用户表格
            columns: [
                {
                    title: '工号',
                    dataIndex: 'jobNumber',
                    sorter: true,
                    ellipsis: true,
                    width: 100,
                    fixed: 'left',
                    align: 'center',
                    scopedSlots: { customRender: 'jobNumber' },
                },
                {
                    title: '姓名',
                    dataIndex: 'realName',
                    sorter: false,
                    width: 100,
                    fixed: 'left',
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'realName' },
                },
                {
                    title: '公司',
                    dataIndex: 'companyName',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'companyName' },
                },
                {
                    title: '一级部门',
                    dataIndex: 'oneDepartment',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'oneDepartment' },
                },
                {
                    title: '二级部门',
                    dataIndex: 'twoDepartment',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'twoDepartment' },
                },
                {
                    title: '员工类型',
                    dataIndex: 'empType',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'empType' },
                },
                {
                    title: '岗位',
                    dataIndex: 'orgPost',
                    sorter: false,
                    ellipsis: true,
                    width: 180,
                    align: 'center',
                    scopedSlots: { customRender: 'orgPost' },
                },
                {
                    title: '招聘来源',
                    dataIndex: 'recruitmentSource',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'recruitmentSource' },
                },
                {
                    title: '身份证号',
                    dataIndex: 'identityCard',
                    sorter: false,
                    ellipsis: true,
                    width: 220,
                    align: 'center',
                    scopedSlots: { customRender: 'identityCard' },
                },
                {
                    title: '性别',
                    dataIndex: 'gender',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'gender' },
                },
                {
                    title: '出生日期',
                    dataIndex: 'birthday',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'birthday' },
                },
                {
                    title: '手机号码',
                    dataIndex: 'mobile',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'mobile' },
                },
                {
                    title: '入职日期',
                    dataIndex: 'hireDate',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'hireDate' },
                },
                {
                    title: '试用期',
                    dataIndex: 'probationMonth',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'probationMonth' },
                },
                {
                    title: '试用期结束日',
                    dataIndex: 'regularDate',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'regularDate' },
                },
                {
                    title: '是否固定期',
                    dataIndex: 'isNotHaveFixedTermCode',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'isNotHaveFixedTermCode' },
                },
                {
                    title: '合同期限',
                    dataIndex: 'dueTerm',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'dueTerm' },
                },
            ],
            tableData: [],
            totalItems: 0, //总数
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ['10', '20', '30', '40'],
            request: { maxResultCount: 10, skipCount: 0, sorting: '' },
        };
    },
    created() {
        // 公司
        this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        //部门
        this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
        // 出勤日期
        this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);

        this._organizationReportServiceProxy = new OrganizationReportServiceProxy(this.$apiUrl, this.$api);
        this._fileDownloadService = fileDownloadService;
    },
    async mounted() {
        this.initCompany();
        await this.getTheData();
        this.empTypeList = await Dic.getInstance().getDicAsync('EmpType');
    },
    methods: {
        refresh() {
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
        },
        getData() {
            this.loading = true;
            this._organizationReportServiceProxy
                .getNewEmployeeReport(
                    this.comPanyId ? _.join(this.comPanyId, ',') : undefined,
                    this.departmentId ? _.join(this.departmentId, ',') : undefined,
                    this.empType,
                    this.startTime ? moment(this.startTime) : undefined,
                    this.endTime ? moment(this.endTime) : undefined,
                    this.regularonStartTime ? moment(this.regularonStartTime) : undefined,
                    this.regularonEndTime ? moment(this.regularonEndTime) : undefined,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.tableData.map((item) => {
                        item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
                        item.birthday = item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : '';
                        item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : '';
                    });
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);

                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },
        Export() {
            if (this.tableData.length <= 0) {
                return abp.message.warn(this.l('NoData'));
            }
            this.loading = true;
            this._organizationReportServiceProxy
                .getNewEmployeeReportToExcel(
                    this.comPanyId ? _.join(this.comPanyId, ',') : undefined,
                    this.departmentId ? _.join(this.departmentId, ',') : undefined,
                    this.empType,
                    this.startTime ? moment(this.startTime) : undefined,
                    this.endTime ? moment(this.endTime) : undefined,
                    this.regularonStartTime ? moment(this.regularonStartTime) : undefined,
                    this.regularonEndTime ? moment(this.regularonEndTime) : undefined,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },
        async getTheData() {
            let a = await this.initAttDate();
            this.startTime = a.startTime;
            this.endTime = a.endTime;
            if (abpService.abp.userInfo.companyId) {
                this.comPanyId = abpService.abp.userInfo.companyId;
                this.handleCompanyChange(this.comPanyId);
            } else {
                this.getData();
            }
        },
        // 初始化出勤日期
        initAttDate() {
            return new Promise((resolve, reject) => {
                this._enterpriseAttendCycleServiceProxy.getCycleByNow().then((res) => {
                    this.initialValue = [moment(res.startTime), moment(res.endTime)];
                    resolve(res);
                });
            });
        },
        //时间段
        onChange(date, dateString) {
            // console.log(date, dateString);
            this.startTime = dateString[0];
            this.endTime = dateString[1];
            this.refresh();
        },
        RegularonChange(date, dateString) {
            this.regularonStartTime = dateString[0];
            this.regularonEndTime = dateString[1];
            this.refresh();
        },
        //初始化公司
        initCompany() {
            this._companyConfigServiceProxy
                .getPaged(undefined, '', 100, 0)
                .finally(() => {
                    // this.spinning = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                    // if (this.companyTreeList.length > 0) {
                    //     this.handleCompanyChange(this.companyTreeList[0].key);
                    // }
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },
        //选择公司
        handleCompanyChange(value) {
            this.comPanyId = value;
            this.refresh();
            this.departmentId = undefined;
            this.getDepTreeData();
        },
        /**
         * 获取部门
         */
        getDepTreeData() {
            this.DepTreeData = [];
            this._departmentServiceProxy
                .getDepTreeData(this.comPanyId)
                .then((res) => {
                    this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
                    this.DepTree.forEach((item) => {
                        if (!item.parentId) {
                            this.DepTreeData.push(item);
                        }
                    });
                    this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
                })
                .finally(() => {
                    // this.loading = false;
                });
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChangePage(page, pageSize) {
            this.selectedRowKeys = [];
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            this.selectedRowKeys = [];
            // this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.refresh();
        },
        /**
         * 排序
         * @param pagination
         * @param filters
         * @param sorter
         */
        sorterChange(pagination, filters, sorter) {
            if (sorter.order) {
                this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
            } else {
                this.request.sorting = undefined;
            }
            this.refresh();
        },
    },
};
</script>

<style scoped>
.margin-left-10 {
    margin-left: 10px;
}
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
